// src/router/router.js
import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ReactModal from "react-modal";
import { AuthContext } from "../context/AuthContext";
import Login from "../pages/Login";
import Example from "../pages/Example";
import Dashboard from "../pages/dashboard";
import { CosmoContext } from "../context/CosmoContext";
import HeaderComponent from "../components/header/headerComponent";
import { Layout } from "../theme/Layout";
import Hamburguermenu from "../components/header/menus/hambuerguermenu";
import MenuMobilModal from "../utils/components/modals/MenuMobilModal";
import useModals from "../utils/components/modals/useModals";
import useAxios from "../api/useAxios";
import FooterComponent from "../components/footer/FooterComponent";
import Stepper from "../components/Stepper/Stepper";
import ModalComponent from "./../utils/components/modals/ModalPrime";
import ModalManual from "./../utils/components/modals/ModalManual";
import ModalMatriz from "../utils/components/modals/ModalMatriz";
import ModalMarco from "../utils/components/modals/MarcoModal";
import useCMS from "../api/CMS/useCMS";
import Objetivo from "../pages/Objetivo";
import Tipoproyecto from "../pages/Tipoproyecto";
import TaskBarComponent from "../components/TaskBar/TaskBarComponent";
import ModalSitemapPrime from "../utils/components/modals/SitemapModal";
import Arrows from "../components/Arrows/Arrows";

const AppRouter = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const { user, loading } = useContext(AuthContext);
  const {
    HandlerRoute,
    ActualRouter,
    windowSize,
    HanlderSelectRouter,
    RutaSeleccionada,
    ColorSeleccionado,
    footerSelected,
    HanlderSelectedFooter,
    SelectedStep,
    HanlderSelectedStep,
    SelectedRouteSteep,
    HanlderSelectRouteSteep,
    isBackground,
    HandlerIsBackground,
    SelectedRouteMenu,
    HanlderSelectRouterMenu,
    ShowArrowBack,
    ShowArrowNext,
    HanlderShowArrowBack,
    HanlderHiddenBackarrow,
    HanlderShowNextArrow,
    HanlderHidenNextArrow,
    RutadeFooter,
    HandlerRutafooter,
    DataFases,
    HanlderFases
  } = useContext(CosmoContext);
  const {
    ModalHamburguesa,
    closeModal,
    openModal,
    MenuDesk,
    handleropenMenuDesk,
    CloseModalOpenDesk,
    ModalManualPrime,
    OpenModalManual,
    CloseModalManual,
    ModalMAtrizPrime,
    OpenModalMatriz,
    CloseModalMatriz,
    ModalMarcoPrime,
    OpenModalMarcoPrime,
    CloseModalMarcoPrime,
    ModalSitemap,
    HandlerOpenSitemap,
    HandlerCloseSitemap,
  } = useModals();

  // LLAMADA A CMS
  const { LoadData, loadingApi, data } = useAxios();
  const {
    LoadFases,
    LoadSteep,
    DataSteep,
    LoadObjetivo,
    DataObjetivo,
    DataProyectos,
    LoadProyectos,
  } = useCMS();

  // LLAMADA PARA MARCO PROCESO
  useEffect(() => {
    LoadData(`${apiUrl}macroproceso/${ColorSeleccionado.substr(1)}`);
  }, [ColorSeleccionado]);

  useEffect(() => {
    HandlerIsBackground(false);
  }, []);

 

  // LLAMADA PARA STEEPER
  useEffect(() => {
    switch (footerSelected) {
      case "DEFINICIÓN ESTRATÉGICA":
        LoadFases(`${apiUrl}fases/definicion-estrategica`);
        HanlderSelectRouteSteep(null);
        HandlerIsBackground(true);
        HanlderHiddenBackarrow()
        break;
      case "IDEACIÓN Y PLAN DE NEGOCIOS":
        LoadFases(`${apiUrl}fases/ideacion`);
        HanlderSelectRouteSteep(null);
        HandlerIsBackground(true);
        break;
      case "DESARROLLO":
        LoadFases(`${apiUrl}fases/desarrollo`);
        HanlderSelectRouteSteep(null);
        HandlerIsBackground(true);
        break;
      case "FABRICACIÓN":
        LoadFases(`${apiUrl}fases/fabricacion`);
        HanlderSelectRouteSteep(null);
        HandlerIsBackground(true);
        break;
      case "SEGUIMIENTO":
        LoadFases(`${apiUrl}fases/seguimiento`);
        HanlderSelectRouteSteep(null);
        HandlerIsBackground(true);
        break;
      default:
        break;
    }
  }, [footerSelected]);

  useEffect(() => {
    switch (ActualRouter?.substring(1)) {
      case "definicion-estrategica":
        HanlderSelectedFooter("DEFINICIÓN ESTRATÉGICA");
        HanlderHiddenBackarrow()
        HanlderSelectRouteSteep(null);
        break;
      case "ideacion":
        HanlderSelectedFooter("IDEACIÓN Y PLAN DE NEGOCIOS");
        HanlderSelectRouteSteep(null);
        break;
      case "desarrollo":
        HanlderSelectedFooter("DESARROLLO");
        HanlderSelectRouteSteep(null);
        break;
      case "fabricacion":
        HanlderSelectedFooter("FABRICACIÓN");
        HanlderSelectRouteSteep(null);
        break;
      case "seguimiento":
        HanlderSelectedFooter("SEGUIMIENTO");
        HanlderSelectRouteSteep(null);
        break;
      case "objetivo":
        HandlerIsBackground(false);
        HanlderSelectRouteSteep(null);
        LoadObjetivo(`${apiUrl}objetivo`);
        break;
        case "tipos-de-proyectos":
          LoadProyectos(`${apiUrl}tipos-de-proyectos`);
          HandlerIsBackground(false);
          HanlderSelectRouteSteep(null);
          break;
      default:
        HandlerIsBackground(false);
        break;
    }
  }, [ActualRouter]);

  useEffect(() => {
    if (SelectedRouteSteep !== null) {
      LoadSteep(`${apiUrl}pasos/${SelectedRouteSteep}`);
    }
  }, [SelectedRouteSteep]);

  useEffect(() => {
    if (SelectedRouteMenu !== null) {
      switch (SelectedRouteMenu) {
        case "objetivo":
          LoadObjetivo(`${apiUrl}objetivo`);
          break;
        case "porque-evolucionar":
          LoadObjetivo(`${apiUrl}introduccion`);
          break;
        case "porque-inovar":
          LoadObjetivo(`${apiUrl}por-que-innovar`);
          break;
        case "principios-inovacion":
          LoadObjetivo(`${apiUrl}principios-de-innovacion`);
          break;
        case "tipos-de-proyectos":
          LoadProyectos(`${apiUrl}tipos-de-proyectos`);
          break;
        default:
          break;
      }
    }
  }, [SelectedRouteMenu]);

  useEffect(() => {
      if(data !== undefined && ActualRouter !== "/"){
        if (data?.data.ruta[0]?.nombre === "DEFINICIÓN ESTRATÉGICA") {
          HanlderHiddenBackarrow()
          HanlderSelectedStep(null)
         // navigate("/definicion-estrategica", { replace: true });
        } else if (data?.data.ruta[0]?.nombre === "IDEACIÓN Y PLAN DE NEGOCIOS") {
          HanlderSelectedStep(null)
         // navigate("/ideacion", { replace: true });
        } else if (data?.data.ruta[0]?.nombre === "DESARROLLO") {
          HanlderSelectedStep(null)
         // navigate("/desarrollo", { replace: true });
        } else if (data?.data.ruta[0]?.nombre === "FABRICACIÓN") {
          HanlderSelectedStep(null)
        //  navigate("/fabricacion", { replace: true });
        } else if (data?.data.ruta[0]?.nombre === "SEGUIMIENTO") {
          HanlderSelectedStep(null)
        //  navigate("/seguimiento", { replace: true });
        }
      }
  }, [data?.data.ruta,ActualRouter])
  

  //

  if (loading) {
    return <p>Loading...</p>;
  }

  const ProtectedRoute = ({ element: Element, ...rest }) => {
    return user !== null ? (
      <Layout>
        <HeaderComponent
          dataRoute={data == null ? [] : data.data.ruta}
          MenuDesk={MenuDesk}
          CloseModalOpenDesk={CloseModalOpenDesk}
          openModal={windowSize.width > 837 ? handleropenMenuDesk : openModal}
          windowSize={windowSize}
          isBackground={isBackground}
          HanlderSelectRouterMenu={HanlderSelectRouterMenu}
          HanlderSelectedFooter={HanlderSelectedFooter}
          HandlerIsBackground={HandlerIsBackground}
        ></HeaderComponent>
        <Element
          DataHeader={data == null ? [] : data.data.iniciativas}
          dataMarco={data == null ? [] : data.data}
          footerSelected={footerSelected}
          HanlderSelectedFooter={HanlderSelectedFooter}
          windowSize={windowSize}
          SelectedStep={SelectedStep}
          SelectedRouteSteep={SelectedRouteSteep}
          dataFases={DataFases}
          dataPasos={DataSteep}
          dataObjetivo={DataObjetivo}
          SelectedRouteMenu={SelectedRouteMenu}
          HanlderSelectRouterMenu={HanlderSelectRouterMenu}
          HandlerIsBackground={HandlerIsBackground}
          DataProyectos={DataProyectos}
          HandlerRoute={HandlerRoute}
          RutaFooter={data?.data.ruta}
          OpenModalMarcoPrime={OpenModalMarcoPrime}
          RutaSeleccionada={RutaSeleccionada}
          {...rest}
        />

        <TaskBarComponent
          openModalMarco={OpenModalMarcoPrime}
          openModalManual={OpenModalManual}
          openModalMatriz={OpenModalMatriz}
          openModalSite={HandlerOpenSitemap}
          windowSize={windowSize}
          DataHeader={data == null ? [] : data.data.iniciativas}
          RutaFooter={data?.data.ruta}
          RutaSeleccionada={RutaSeleccionada}
          HanlderSelectRouter={HanlderSelectRouter}
          HanlderSelectedStep={HanlderSelectedStep}
          HanlderSelectedFooter={HanlderSelectedFooter}
          footerSelected={footerSelected}
          dataFases={DataFases}
          Rutas={data?.data.ruta}
          ActualRouter={ActualRouter}
          RutadeFooter={RutadeFooter}
  
        ></TaskBarComponent>

       <div>
       <div style={{
          position: 'relative',
          bottom:  windowSize.width > 837 ? 5 : -70,
        
          left: 0,
          width: '100%'
        }}>
          <Arrows
           Rutas={data?.data.ruta}
           SelectedStep={SelectedStep}
           ActualRouter={ActualRouter}
           HanlderSelectedStep={HanlderSelectedStep}
           HanlderSelectRouteSteep={HanlderSelectRouteSteep}
           HanlderSelectedFooter={HanlderSelectedFooter}
           HandlerIsBackground={HandlerIsBackground}
           ShowArrowNext={ShowArrowNext}
           dataFases={DataFases}
           dataPasos={DataSteep}
           dataObjetivo={DataObjetivo}
           RutaSeleccionada={RutaSeleccionada}
          ></Arrows>
        </div>
         <FooterComponent
          data={data?.data.ruta}
          HanlderSelectedFooter={HanlderSelectedFooter}
          footerSelected={footerSelected}
          ActualRouter={ActualRouter}
          HanlderSelectedStep={HanlderSelectedStep}
          SelectedStep={SelectedStep}
          windowSize={windowSize}
          SelectedRouteSteep={SelectedRouteSteep}
          HanlderSelectRouteSteep={HanlderSelectRouteSteep}
          HandlerIsBackground={HandlerIsBackground}
          HanlderShowArrowBack={HanlderShowArrowBack}
        ></FooterComponent>
       </div>

        <MenuMobilModal
          modal={ModalHamburguesa}
          closemodal={closeModal}
          dataRoute={data == null ? [] : data.data}
          openModalMarco={OpenModalMarcoPrime}
          openModalManual={OpenModalManual}
          openModalMatriz={OpenModalMatriz}
          HanlderSelectedFooter={HanlderSelectedFooter}
          HanlderSelectRouterMenu={HanlderSelectRouterMenu}
          openModalSite={HandlerOpenSitemap}
          DataHeader={data == null ? [] : data.data.iniciativas}
          RutaFooter={data?.data.ruta}
          RutaSeleccionada={RutaSeleccionada}
          HanlderSelectRouter={HanlderSelectRouter}
        ></MenuMobilModal>

        <ModalManual
          modal={ModalManualPrime}
          closemodal={CloseModalManual}
          dataRoute={data == null ? [] : data.data}
          Tipo="Manual"
          windowSize={windowSize}
        />
        <ModalMatriz
          modal={ModalMAtrizPrime}
          closemodal={CloseModalMatriz}
          dataRoute={data == null ? [] : data.data}
          Tipo="Matriz"
          windowSize={windowSize}
        />
        <ModalMarco
          modal={ModalMarcoPrime}
          closemodal={CloseModalMarcoPrime}
          dataRoute={data == null ? [] : data.data}
          Tipo="Marco"
          windowSize={windowSize}
        ></ModalMarco>

        <ModalSitemapPrime
          modal={ModalSitemap}
          closemodal={HandlerCloseSitemap}
          dataRoute={data == null ? [] : data.data}
          Tipo="Map"
          windowSize={windowSize}
        >
        </ModalSitemapPrime>
      </Layout>
    ) : (
      <Navigate to="/login" replace />
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute element={Dashboard} />} />
        <Route path="/home" element={<ProtectedRoute element={Dashboard} />} />
        <Route
          path="/definicion-estrategica"
          element={<ProtectedRoute element={Stepper} />}
        />
        <Route
          path="/definicion-estrategica/:pasos"
          element={<ProtectedRoute element={Stepper} />}
        />
        <Route
          path="/ideacion"
          element={<ProtectedRoute element={Stepper} />}
        />
        <Route
          path="/ideacion/:pasos"
          element={<ProtectedRoute element={Stepper} />}
        />
        <Route
          path="/desarrollo"
          element={<ProtectedRoute element={Stepper} />}
        />
        <Route
          path="/fabricacion"
          element={<ProtectedRoute element={Stepper} />}
        />
        <Route
          path="/seguimiento"
          element={<ProtectedRoute element={Stepper} />}
        />

        <Route
          path="/objetivo"
          element={<ProtectedRoute element={Objetivo} />}
        />

        <Route
          path="/tipos-de-proyectos"
          element={<ProtectedRoute element={Tipoproyecto} />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
